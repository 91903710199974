import {
	postMessageToNativeApp,
	isWebViewBridgeAvailable,
} from './post-message';

/*
	Types
*/
type setHeaderMenuItemClickHandlerType = (handler: (id: 'menu' | 'notifications') => any) => void
type setHeaderMenuItemsType = (menuItems: Array<MenuItemsType>) => Promise<void>
type HeaderMenuIconsType = 'Notifications' | 'Menu' | 'Person' | 'Close'

export type MenuItemsType = {
	id: string;
	icon: HeaderMenuIconsType;
	badge?: string;
}

export type HeaderMenuItemsType = {
	setItems: setHeaderMenuItemsType;
	setClickHandler: setHeaderMenuItemClickHandlerType;
}

/*
	Methods
*/

/**
 * Object contains methods to set menu items in the app's header
 */
export const headerMenuItems: HeaderMenuItemsType = {
	setItems: (menuItems): Promise<void> => {
		if (isWebViewBridgeAvailable()) {
			return UpdateMenuItems(menuItems);
		} else {
			return Promise.resolve();
		}
	},
	setClickHandler: (handler) => {
		if (isWebViewBridgeAvailable()) {
			(window as any).onOrtaBridgeHeaderMenuItemClick = handler;
		} else if (typeof window !== 'undefined') {
			console.log('--setHeaderMenuItemClickHandler-isUnknown');
		}
	}
}

const UpdateMenuItems = (menuItems: Array<MenuItemsType>): Promise<void> =>
	postMessageToNativeApp({
		type: 'SET_HEADER_MENU_ITEMS',
		payload: menuItems,
	});

export const close = (): Promise<void> => postMessageToNativeApp({
	type: 'CLOSE'
});

/**
 * Method for managing URLs using device instead of Orta miniapp. Orta mobile client will delegate the decision to the device to handle the provided URL.
 * @param url - string URL
 * @returns a promise, which can either resolve or reject.
 */
export const openUrl = (url: string): Promise<string> => postMessageToNativeApp({
	type: 'OPEN_URL',
	payload: url,
}, 500)

export const pwa = (): Promise<void> => postMessageToNativeApp({
	type: 'PWA',
}, 1000)